function parseResponseErrors (error) {
  let errorMessages = []
  if (error.code === 'ECONNABORTED') {
    errorMessages.push('The request timed out before it was able to be completed. Please try again later.')
    return errorMessages
  }
  if (!error.response || !error.response.data) {
    errorMessages.push('Something went horribly wrong.')
    return errorMessages
  }
  if (Array.isArray(error.response.data)) {
    errorMessages = error.response.data
  } else if (typeof error.response.data === 'object') {
    for (var property in error.response.data) {
      if(error.response.data[property] == "This field may not be null.") {
        errorMessages = errorMessages.concat(`The ${property} field may not be empty.`)
      } else {
        errorMessages = errorMessages.concat(error.response.data[property])
      }
    }
  } else {
    errorMessages.push(error.response.data)
  }
  return errorMessages
}

export {
  parseResponseErrors
}
