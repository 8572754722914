<template>
  <v-card
    outlined
    :to="{ name: 'Story', params: { slug: story.slug }}"
    class="mb-4"
  >
    <v-img
      :src="(story.image) ? story.image : ''"
      height="200px"
      class="px-3"
    >
      <v-row class="mb-4">
        <v-col>
          <div class="headline">
            {{ story.name }}
          </div>
          <div class="title">
            {{ story.num_quests }} Quests
          </div>
        </v-col>
      </v-row>
      <v-row class="align-end pt-5">
        <v-col>
          <span>{{ story.created|formatDate }}</span>
        </v-col>
        <v-col class="text-right">
          <div class="title">
            {{ story.points_available_total }}
          </div>
          <div>
            points
          </div>
        </v-col>
      </v-row>
    </v-img>
  </v-card>
</template>

<script>
export default {
  props: {
    story: {
      type: Object,
      required: true,
    }
  },
}
</script>
