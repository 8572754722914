<template>
  <v-card
    outlined
    :to="to"
    class="mb-4"
  >
    <v-img
      :src="(quest.image) ? quest.image : ''"
      height="200px"
      class="px-3"
    >
      <div
        v-if="participant && participant.status == 'pending'"
        class="tile-overlay text-h5"
      >
        Pending Approval
      </div>
      <div
        v-else-if="participant && participant.status == 'rejected'"
        class="tile-overlay text-h5"
      >
        Evidence Rejected
      </div>
      <div
        v-else-if="isComplete"
        class="tile-overlay text-h5"
      >
        <v-icon x-large>
          $check
        </v-icon>
      </div>
      <div
        v-else-if="isLocked"
        class="tile-overlay text-h5"
      >
        <v-icon x-large>
          $lock
        </v-icon>
      </div>
      <v-row>
        <v-col>
          <div class="headline">
            {{ quest.name }}
          </div>
          <div class="title">
            {{ quest.region.name }}
          </div>
          <div>
            <v-icon
              v-if="quest.quest_type.name == 'Chain'"
              small
            >
              $chain
            </v-icon>
            <v-icon
              v-else-if="quest.quest_type.name == 'Terra'"
              small
            >
              $terra
            </v-icon>
            <span v-else>
              {{ quest.quest_type.name }}
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row class="align-end">
        <v-col>
          <span>{{ quest.created|formatDate }}</span>
        </v-col>
        <v-col class="text-right">
          <div class="title">
            {{ quest.total_points }}
          </div>
          <div>
            points
          </div>
        </v-col>
      </v-row>
    </v-img>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    quest: {
      type: Object,
      required: true,
    },
    participant: {
      type: Object,
      required: false,
      default: null,
    },
    to: {
      type: Object,
      required: false,
      default: function () { return { name: 'Quest', params: { slug: this.quest.slug }} }
    },
  },
  computed: {
    ...mapState({
      locked: state => state.quests.locked,
      profile: state => state.auth.profile,
    }),
    isLocked() {
      return this.locked.find(id => id === this.quest.id)
    },
    isComplete() {
      if (this.profile.completed_quest_id_list.indexOf(this.quest.id) == -1) {
        return false
      }
      return true
    },
  },
}
</script>
