<template>
  <span class="like">
    <v-btn
      :disabled="submitting"
      @click="doLike"
    >
      <v-icon v-if="hasLiked">
        $liked
      </v-icon>
      <v-icon v-else>
        $like
      </v-icon>
    </v-btn>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
    >
      {{ snackbarText }}
    </v-snackbar>
  </span>
</template>

<script>
import { allianceAPI } from '@/api'
import { parseResponseErrors } from '@/utils/errors'

export default {
  props: {
    model: {
      type: String,
      required: true,
    },
    app: {
      type: String,
      required: true,
    },
    objectId: {
      type: Number,
      required: true,
    },
  },
  data: function() {
    return {
      submitting: null,
      snackbar: null,
      snackbarText: null,
      errorMessages: null,
    }
  },
  computed: {
    hasLiked() {
      return this.$store.getters['likes/hasLiked']({
        object_id: this.objectId,
        app: this.app,
        model: this.model,
      }) || null
    },
  },
  methods: {
    doLike() {
      if (this.hasLiked) {
        this.snackbarText = 'You have already liked this!'
        this.snackbar = true
      }
      if (!this.$online) {
        this.snackbarText = 'An internet connection is required to sumbit your like.'
        this.snackbar = true
      }
      this.submitting = true
      this.errorMessages = null
      const data = {
        'object_id': this.objectId,
        'content_type': {"model": this.model, "app_label": this.app},
      }
      return allianceAPI.post('/alliance/like/', data)
        .then((response) => {
          console.log('success!')
          console.log(response)
          this.$store.dispatch('likes/replace', {replacementObject: response.data})
          this.snackbarText = 'Thank you for your like!'
          this.snackbar = true
        })
        .catch((error) => {
          console.log(error)
          this.errorMessages = parseResponseErrors(error)
          this.success = false
          // Add a more useful unique error.
          let uniqueIndex = this.errorMessages.indexOf('Object already liked.')
          if (uniqueIndex > -1) {
            this.snackbarText = 'You have already liked this!'
          } else {
            this.snackbarText = 'Failed to save like.'
          }
          this.snackbar = true
        })
        .finally(() => this.submitting = false)
    },
  },
}
</script>
