<template>
  <v-card>
    <v-card-text>
      <Loading
        v-if="loading"
        :loading="loading"
        message="Loading Stories"
      />
      <div v-else-if="storyList.length">
        Showing {{ storyList.length }} active stories
        <v-row>
          <v-col
            v-for="story in storyList"
            :key="story.id"
            cols="12"
            md="6"
            xl="4"
          >
            <v-sheet min-height="200">
              <v-lazy
                v-model="story.isActive"
                :options="{ threshold: .2 }"
              >
                <StoryTile :story="story" />
              </v-lazy>
            </v-sheet>
          </v-col>
        </v-row>
        <v-btn
          v-if="nextPage"
          @click="loadNextPage"
        >
          load more
        </v-btn>
        <p v-if="fetchedAt">
          Fetched at: {{ fetchedAt }}
        </p>
      </div>
      <v-alert
        v-else-if="errored"
        type="error"
      >
        Failed to fetch stories.
      </v-alert>
      <div v-else>
        No stories found.
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import StoryTile from '@/components/StoryTile.vue'
import Loading from '@/components/Loading.vue'

export default {
  components: {
    StoryTile,
    Loading,
  },
  props: {
    authorId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapState({
      allStories: state => state.stories.all,
      loading: state => state.stories.loading,
      fetchedAt: state => state.stories.fetchedAt,
      errored: state => state.stories.errored,
      nextPage: state => state.stories.next,
    }),
    storyList() {
      if (this.authorId) {
        return this.$store.getters['stories/search']([{"author.id": this.authorId}])
      }
      return this.allStories
    },
  },
  created: function () {
    this.$store.dispatch('stories/getAll')
  },
  methods: {
    loadNextPage () {
      this.$store.dispatch('stories/loadNextPage')
    },
  },
}
</script>
