<template>
  <div id="home-map-container">
    <VueMapboxMap
      v-if="map"
      :map="map"
      :interactive="true"
      :lng="scene.lng"
      :lat="scene.lat"
      :zoom="scene.zoom"
      :pitch="scene.pitch"
      :bearing="scene.bearing"
      :arena-list="arenaList"
    />
  </div>
</template>

<script>
import VueMapboxMap from '@benchmark-urbanism/vue-mapbox-map'
import bbox from '@turf/bbox'
import mapboxgl from "mapbox-gl";

export default {
  components: {
    VueMapboxMap
  },
  props: {
    questList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      map: null,
      scene: {
        lng: -104.823574,
        lat: 39.707895,
        zoom: 3,
        pitch: 0,
        bearing: 0,
      },
      visibleSlugs: [],
      layerIDs: [],
      arenaList: null,
      featureCollection: null,
    }
  },
  watch: {
    visibleSlugs: function () {
      // Emit an event when the list of visible quest arena slugs changes.
      this.$emit('mapChange', this.visibleSlugs)
    },
  },
  created: function () {
    this.buildArenaList()
  },
  mounted: function () {
    mapboxgl.accessToken = this.$mapboxAccessToken
    this.map = new mapboxgl.Map({
      container: 'home-map-container',
      style: this.$mapboxStyle,
    })
    this.map.on('load', () => {
      this.setMap()
    })
  },
  methods: {
    buildArenaList() {
      // Build a list of arenas from every quest.
      this.arenaList = []
      this.featureCollection = {
        type: "FeatureCollection",
        features: [],
      }
      for (let i =0; i < this.questList.length; i++) {
        const quest = this.questList[i]
        if (quest.arena_geo) {
          this.arenaList.push({
            id: quest.id,
            slug: quest.slug,
            geo: quest.arena_geo,
            area: quest.arena_area,
          })
          // Add the arena to the collection of all arenas.
          if (quest.arena_geo.type == 'FeatureCollection') {
            this.featureCollection.features.push(...quest.arena_geo.features)
          } else if (quest.arena_geo.type == 'Feature')  {
            this.featureCollection.features.push(quest.arena_geo)
          }
        }
      }
      // Sort the arena by area, descending.
      this.arenaList.sort(function(a, b) {
          return b.area - a.area;
      })
    },
    setMap () {
      // Draw any arenas that already exist.
      this.drawMapLayers()
      // Monitor movement.
      this.map.on('moveend', this.getVisible)
    },
    getVisible (movement) {
      // If the movement has an associated event, it means the user moved the
      // map and we should calculate visibility. If the map was automatically
      // moved, such as via .fitBounds, it will have no event, and we should
      // not calculate visibility.
      if (movement.originalEvent) {
        const visibleFeatures = this.map.queryRenderedFeatures({
          layers: this.layerIDs,
        });
        this.visibleSlugs = visibleFeatures.map(x => x.source)
      }
    },
    drawMapLayers () {
      for (let i =0; i < this.arenaList.length; i++) {
        let arena = this.arenaList[i]
        // If a layer with the current ID is already on the map, skip it.
        if (!this.map.getSource(arena.slug)) {
          // Draw the arena on the map.
          this.map.addSource(arena.slug, {
            'type': 'geojson',
            'data': arena.geo,
          })
          this.map.addLayer({
            'type': 'fill',
            'id': arena.slug,
            'source': arena.slug,
            'layout': {},
            'paint': {
              'fill-color': '#f0f0f0',
              'fill-opacity': 0.3,
            },
          })
          this.layerIDs.push(arena.slug)
        }
      }
      // Calculate a boundary box for the collection of all arenas.
      if (this.featureCollection) {
        const bounds = bbox(this.featureCollection);
        this.map.fitBounds(bounds, {padding: 50});
      }
    },
  },
}
</script>

<style>
@import url("https://api.mapbox.com/mapbox-gl-js/v2.3.1/mapbox-gl.css");
#home-map-container {
  height: 500px;
}
</style>
