<template>
  <v-card>
    <v-card-actions v-if="!loading && !hideActions">
      <v-dialog
        v-model="filterDialog"
        max-width="800px"
      >
        <v-card>
          <v-toolbar>
            <v-row align="center">
              <v-col>
                <v-btn
                  icon
                  @click="filterDialog = false"
                >
                  <v-icon>
                    $close
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col class="text-center">
                <v-toolbar-title>
                  Filter
                </v-toolbar-title>
              </v-col>
              <v-col class="text-right">
                <v-btn
                  text
                  color="primary"
                  @click="resetFilterSelections"
                >
                  Reset
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
          <v-form
            ref="form"
            @submit.prevent="submitFilters"
          >
            <v-card-text>
              <v-text-field
                v-model="searchQuery"
                label="Search"
                type="text"
                clearable
              />
              <v-select
                v-if="!regionId"
                v-model="filterRegion"
                :items="regionList"
                item-text="name"
                item-value="id"
                label="Region"
                clearable
              />
              <v-select
                v-model="filterQuestType"
                :items="questTypes"
                item-text="name"
                item-value="id"
                label="Quest Type"
                clearable
              />
              <v-select
                v-model="filterQuestSkills"
                :items="questSkills"
                item-text="name"
                item-value="id"
                label="Quest Skills"
                multiple
                clearable
              />
              <v-select
                v-model="filterSort"
                :items="filterSortOptions"
                item-text="name"
                item-value="id"
                label="Sort"
                clearable
              />
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                color="primary"
                type="submit"
              >
                Show Filter Results
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-btn @click.stop="filterDialog = true">
        <v-icon>
          $filter
        </v-icon>
      </v-btn>
      <v-btn
        v-if="canShowMap"
        @click="toggleMap"
      >
        <v-icon>
          $map
        </v-icon>
      </v-btn>
      <v-btn
        @click="sortByDistance"
      >
        <v-icon>
          $location
        </v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <DrawMap
        v-if="showMap"
        :quest-list="initialQuestList"
        @mapChange="mapFiltered($event)"
      />
      <Loading
        v-if="loading"
        :loading="loading"
        message="Loading Quests"
      />
      <div v-else-if="filteredQuestList.length">
        Showing {{ filteredQuestList.length }} quests
        <v-row>
          <v-col
            v-for="quest in filteredQuestList"
            :key="quest.id"
            cols="12"
            md="6"
            xl="4"
          >
            <v-sheet min-height="200">
              <v-lazy
                v-model="quest.isActive"
                :options="{ threshold: .2 }"
              >
                <QuestTile :quest="quest" />
              </v-lazy>
            </v-sheet>
          </v-col>
        </v-row>
        <v-btn
          v-if="nextPage"
          @click="loadNextPage"
        >
          load more
        </v-btn>
        <p v-if="fetchedAt">
          Fetched at: {{ fetchedAt }}
        </p>
      </div>
      <v-alert
        v-else-if="errored"
        type="error"
      >
        Failed to fetch Quests.
      </v-alert>
      <div v-else>
        No Quests found.
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import DrawMap from '@/components/DrawMap.vue'
import QuestTile from '@/components/QuestTile.vue'
import Loading from '@/components/Loading.vue'
import { isQuestLocked } from '@/utils/quest'

export default {
  components: {
    DrawMap,
    QuestTile,
    Loading,
  },
  props: {
    hideActions: {
      type: Boolean,
      required: false,
      default: false,
    },
    regionId: {
      type: Number,
      required: false,
      default: null,
    },
    storyId: {
      type: Number,
      required: false,
      default: null,
    },
    authorId: {
      type: Number,
      required: false,
      default: null,
    },
    seasonId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data () {
    return {
      searchQuery: null,
      showMap: false,
      // Filtering crap.
      initialQuestList: [],
      filteredQuestList: [],
      searchTerms: [],
      filterDialog: null,
      filterSortOptions: [
        {id: 1, direction: 'asc', property: 'minimum_points', name: 'Minimum Points (asc)'},
        {id: 2, direction: 'desc', property: 'minimum_points', name: 'Minimum Points (desc)'},
        {id: 3, direction: 'asc', property: 'total_points', name: 'Total Points (asc)'},
        {id: 4, direction: 'desc', property: 'total_points', name: 'Total Points (desc)'},
        {id: 5, direction: 'asc', property: 'name', name: 'Name (asc)'},
        {id: 6, direction: 'desc', property: 'name', name: 'Name (desc)'},
        {id: 7, direction: 'asc', property: 'modified', name: 'Date (asc)'},
        {id: 8, direction: 'desc', property: 'modified', name: 'Date (desc)'},
      ],
      filterQuestType: null,
      filterQuestSkills: null,
      filterSort: null,
      filterRegion: null,
      filterSlugs: null,
      // Sorting
      currentSort: null,
      currentSortDir: 'asc',
    }
  },
  computed: {
    ...mapState({
      errored: state => state.quests.errored,
      fetchedAt: state => state.quests.fetchedAt,
      loading: state => state.quests.loading,
      locked: state => state.quests.locked,
      nextPage: state => state.quests.next,
      profile: state => state.auth.profile,
      questList: state => state.quests.all,
      questSkills: state => state.quests.questSkills,
      questTypes: state => state.quests.questTypes,
      regionList: state => state.regions.all,
      userCoordinates: state => state.userlocation.coordinates,
    }),
    canShowMap() {
      // The map may be shown if the user is online. If they are offline, but
      // the map is already shown, still return true so that the button is
      // present for them to hide the map.
      return this.$online || this.showMap
    },
  },
  watch: {
    profile: function () {
      this.updateLockList()
    },
    questList: function () {
      this.updateLockList()
    },
  },
  created: function () {
    this.$store.dispatch('quests/getAll').finally(() => this.init())
  },
  methods: {
    init() {
      this.resetFilterSelections()
      this.resetFilters()
      this.initialQuestList = this.doFilterSort()
      this.updateLockList()
    },
    toggleMap() {
      if (this.showMap) {
        this.showMap = false
        // Reset map filtering when map is toggled off.
        this.filterSlugs = null
        this.submitFilters()
      } else {
        this.showMap = true
      }
    },
    mapFiltered(visibleSlugs) {
      this.filterSlugs = visibleSlugs
      this.submitFilters()
    },
    isQuestHidden(quest) {
      return quest.hide_if_locked && this.locked.find(id => id === quest.id)
    },
    updateLockList() {
      if (this.questList.length && this.profile) {
        const locked = []
        for (let i=0; i < this.questList.length; i++) {
          const quest = this.questList[i]
          if (isQuestLocked(quest, this.profile)) {
            locked.push(quest.id)
          }
        }
        this.$store.dispatch('quests/setLocked', locked)
      }
    },
    submitFilters() {
      this.resetFilters()
      if (this.filterSlugs) {
        this.searchTerms.push({"slug": this.filterSlugs})
      }
      if (this.filterQuestType) {
        this.searchTerms.push({"quest_type.id": this.filterQuestType})
      }
      if (this.filterRegion) {
        this.searchTerms.push({"region.id": this.filterRegion})
      }
      if (this.filterQuestSkills){
        this.searchTerms.push({"skills.id": this.filterQuestSkills})
      }
      if (this.searchQuery) {
        this.searchTerms.push({name: this.searchQuery, _text: true})
      }
      this.setSort()
      this.filterDialog = null
      this.doFilterSort()
    },
    resetFilterSelections() {
      this.filterSlugs = null
      this.filterQuestType = null
      this.filterQuestSkills = null
      this.filterSort = null
      this.filterRegion = null
      this.searchQuery = null
    },
    resetFilters() {
      this.searchTerms = []
      this.setDefaultSearchTerms()
      this.currentSort = null
      this.currentSortDir = 'asc'
    },
    setDefaultSearchTerms() {
      if (this.regionId) {
        this.searchTerms.push({"region.id": this.regionId})
      }
      if (this.storyId) {
        this.searchTerms.push({"story.id": this.storyId})
      }
      if (this.authorId) {
        this.searchTerms.push({"author.id": this.authorId})
      }
      if (this.seasonId) {
        this.searchTerms.push({"season.id": this.seasonId})
      }
    },
    setSort () {
      // Set to the selected value, if it exists.
      if (this.filterSort) {
        const selectedSort = this.filterSortOptions.find(x => x.id === this.filterSort)
        this.currentSort = selectedSort.property
        this.currentSortDir = selectedSort.direction
      }
    },
    doFilterSort() {
      //console.log(JSON.stringify(this.searchTerms))
      let result = []
      if (this.searchTerms.length) {
        result = this.$store.getters['quests/search'](this.searchTerms)
      } else {
        result = this.questList
      }
      result = result.filter(quest => !this.isQuestHidden(quest))
      if (this.currentSort) {
        result.sort((a,b) => {
          let modifier = 1;
          if(this.currentSortDir === 'desc') modifier = -1;
          if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          return 0;
        })
      }
      this.filteredQuestList = result
      this.resetVisibility()
      return this.filteredQuestList
    },
    resetVisibility() {
      // Mark the first 12 elements as visible. Any remaining should be
      // lazily-loaded.
      for (let i=0; i < this.filteredQuestList.length; i++) {
        if (i < 12) {
          this.filteredQuestList[i].isActive = true
        } else {
          this.filteredQuestList[i].isActive = false
        }
      }
    },
    loadNextPage () {
      this.$store.dispatch('quests/loadNextPage')
    },
    async sortByDistance () {
      await this.$store.dispatch('userlocation/get')
      if (this.userCoordinates) {
        this.$store.dispatch('quests/calcDistance')
        let result = this.$store.getters['quests/sortByDistance']
        this.filteredQuestList = result.filter(quest => !this.isQuestHidden(quest))
      }
    },
  },
}
</script>
