function isQuestLocked(quest, profile) {
  if (quest.prereq_min_points && profile.points_earned < quest.prereq_min_points) {
    return true
  }
  if (quest.prereq_num_completed_quests && profile.completed_quest_id_list.length < quest.prereq_num_completed_quests) {
    return true
  }
  for (let i = 0; i < quest.prereq_completed_quests.length; i++) {
    let prereqQuest = quest.prereq_completed_quests[i]
    if (profile.completed_quest_id_list.indexOf(prereqQuest.id) == -1) {
      return true
    }
  }
  // TODO asset hold check
  return false
}

export {
  isQuestLocked
}
