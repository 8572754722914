<template>
  <span class="share">
    <v-btn
      @click="doShare"
    >
      <v-icon>
        $share
      </v-icon>
    </v-btn>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
    >
      {{ snackbarText }}
    </v-snackbar>
  </span>
</template>

<script>

export default {
  props: {
    url: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: function() {
        return `Check out ${this.title} on Quest.`
      },
    },
    copyMessage: {
      type: String,
      required: false,
      default: 'The URL was succesfully copied to your clipboard.',
    },
  },
  data: function() {
    return {
      snackbar: null,
      snackbarText: null,
      shareUrl: null,
    }
  },
  methods: {
    doShare() {
      if (this.url) {
        this.shareUrl = this.url
      } else {
        this.shareUrl = window.location.href
      }
      try {
        navigator.share({
          title: this.title,
          text: this.text,
          url: this.shareUrl,
        })
          .then(() => console.log('Successful share'))
          .catch(() => this.copyURL());
      } catch (error) {
        this.copyURL()
      }
    },
    copyURL() {
      this.$copyText(this.shareUrl).then(() => {
        this.snackbarText = this.copyMessage
        this.snackbar = true
      })
    },
  },
}
</script>
