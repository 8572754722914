<template>
  <div class="author">
    <v-alert
      v-for="alert in alerts"
      :key="alert.content"
      :type="alert.type"
    >
      {{ alert.message }}
    </v-alert>
    <Loading
      :loading="loading"
      message="Loading Author"
    />
    <div v-if="author">
      <v-card>
        <v-img
          :src="(author.image) ? author.image : ''"
          height="200px"
          class="px-3"
        >
          <v-row class="mb-4">
            <v-col>
              <div class="headline">
                {{ author.name }}
              </div>
              <div class="title">
                {{ author.num_quests }} Quests
              </div>
            </v-col>
          </v-row>
          <v-row class="align-end pt-5">
            <v-col>
              <div class="title">
                {{ author.num_likes }}
              </div>
              <div>
                Likes
              </div>
            </v-col>
          </v-row>
        </v-img>
      </v-card>

      <v-row>
        <v-col>
          <Share
            :title="author.name"
            class="mr-4"
          />
          <Like
            :object-id="author.id"
            app="quest"
            model="author"
          />
        </v-col>
      </v-row>

      <div class="section">
        <div class="section-title">
          Dispatch
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="author.dispatch" />
        <!--eslint-enable-->
      </div>

      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab>
          Stories
        </v-tab>
        <v-tab>
          Quests
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
      >
        <v-tab-item>
          <story-list :author-id="author.id" />
        </v-tab-item>
        <v-tab-item>
          <quest-list
            :author-id="author.id"
            :hide-actions="true"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import Like from '@/components/Like.vue'
import Loading from '@/components/Loading.vue'
import QuestList from '@/components/QuestList.vue'
import StoryList from '@/components/StoryList.vue'
import Share from '@/components/Share.vue'

export default {
  components: {
    Like,
    Loading,
    QuestList,
    Share,
    StoryList,
  },
  data: function() {
    return {
      alerts: [],
      author: null,
      loading: false,
      tab: null,
    }
  },
  created: function () {
    this.id = this.$route.params.id
    this.getauthor()
  },
  methods: {
    getauthor() {
      this.loading = true
      return this.$store.dispatch('authors/getAll')
        .finally(() => {
          this.author = this.$store.getters['authors/getByID'](this.id) || null
          this.loading = false
          if (!this.$online && !this.author) {
            this.alerts.push({
              type: 'error',
              message: 'An internet connection is required to load this author.',
            })
          } else if (!this.author) {
            this.alerts.push({
              type: 'error',
              message: 'Failed to load author.',
            })
          }
        })
    },
  },
}
</script>
